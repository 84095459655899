label
	display: block
	width: 100%
	margin-bottom: .25em

input
	display: block
	width: 100%
	font-weight: 400
	font-size: 1em
	line-height: 3em
	height: 3em
	border: 1px solid #002349
	text-indent: 1em
	-moz-appearance: none
	-webkit-appearance: none
	-moz-border-radius: 0
	-webkit-border-radius: 0
	border-radius: 0
	
	&:focus
		border-color: #0063CC

textarea
	width: 100%
	max-width: 100%
	font-weight: 400
	font-size: 1em
	line-height: 1.33em
	border: 1px solid #002349
	padding: .75em 1em
	-moz-appearance: none
	-webkit-appearance: none
	-moz-border-radius: 0
	-webkit-border-radius: 0
	border-radius: 0

@css {		
	::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		color: #A9A9A9;
	}
	
	input:focus::-webkit-input-placeholder {
		opacity: 0;
	}
	
	textarea:focus::-webkit-input-placeholder {
		opacity: 0;
	}
	
	::-moz-placeholder { /* Firefox 19+ */
		color: #A9A9A9;
	}
	
	input:focus::-moz-placeholder {
		opacity: 0;
	}
	
	textarea:focus::-webkit-input-placeholder {
		opacity: 0;
	}
	
	:-ms-input-placeholder { /* IE 10+ */
		color: #A9A9A9;
	}
	
	:-moz-placeholder { /* Firefox 18- */
		color: #A9A9A9;
	}
}

.form_row
		margin-bottom: 1.25em

.field_error
	list-style-type: none
	color: #CD5151
	font-size: .875em
	margin: 5px 0

.form_errors
	list-style-type: none
	margin-bottom: 3em
	padding: 1em
	background-color: white
	border: 1px solid #0063CC
	line-height: 1.5em
	color: #CD5151

.charcount
	padding-left: 10px
	font-style: italic
	font-size: .75em
