.navigation
	overflow: hidden

	.toggle
		display: none
		float: right
		padding: 1.875rem 0.75rem
		border: 1px solid transparent
		background: 0 0
		font-size: 1.75rem
		line-height: 1
		font-family: 'Roboto', 'sans-serif'
		
	.toggleable
		display: block;

	.brand
		float: left
		margin: .5rem 1rem 0 0;
		font-size: 0

	.items
		list-style-type: none

	.item
		float: left
		
		&+.item
			margin-left: 1.75rem

	.link
		display: block
		padding: 2.032rem 0
		border-top: 3px solid #fff
		transition: border-color .7s
		color: #4c4c4c
		
		&:hover
			border-top: 3px solid #999999 // #6F819C

	.nav
		display: inline-block
		height: 78px
		list-style-type: none
		overflow: hidden

		li
			display: inline-block
			height: 100%
			vertical-align: middle


@media screen and (max-width: 991px)
	.navigation
		.toggle
			display: block
			
		.toggleable
			max-height: 0
			overflow: hidden
			transition: max-height .75s
			clear: both
			&.open
				max-height: 100vh
				overflow: initial
				
		.brand
			background-size: 248px 68px
			width: 248px
			height: 68px
			margin-top: 0.6875rem
			
		.items
			padding: 5px 0
			background-color: #F5F5F5
			
		.item
			float: none
			&+.item
				margin-left: 0
				margin-top: 10px
				
		.link
			padding: 6px 10px
			border: none
			
			&:hover
				border: none
				background-color: #999999
				color: #fff
