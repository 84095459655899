@require "resets/normalize"
@require "resets/reset"

html
	font-family: 'Open Sans', sans-serif
	color: #585858
	
body,
html
	background-color: #f5f5f5

a
	max-width: 100%
	
a, a:link, a:visited, a:hover
	color: #585858
	text-decoration: none

fieldset
	border: none
	padding: 0

:focus
	outline: none

@require "containers"
@require "components/nav"
@require "components/button"
@require "components/form"
@require "components/slick"
@require "components/slider"
@require "components/icons"

@require "sections/agent-login"
@require "sections/offices"

// $fa-font-path = "http://netdna.bootstrapcdn.com/font-awesome/4.5.0/fonts"
// @import "../../bower_components/Font-Awesome-Stylus/stylus/index"

.wrapper
	background-color: #fff;

.site-header
	position: fixed
	top: 0
	left: 0
	right: 0
	z-index: 999
	background-color: #fff
	border-bottom: 1px solid #999999

.site-header-main
	// padding: 1em 0
	margin: 0 auto
	max-width: 1140px

.brand
	display: block
	background: url('../images/signature-sothebys-bw.svg') no-repeat 0 0/285px 78px
	width: 285px
	height: 78px
	
	.brand-label
		display: none

	.brand-logo
		display: none

@media screen and (max-width: 768px)
	.site-header-main
		margin-bottom: 0
		padding: 0
	
	.brand
		float: none
		margin: 0 auto

.site-content
	padding-top: 90px

.page-home .site-content
	position: relative;
	height: calc(100vh)
	height: -webkit-calc(100vh)

.page-header
	background-color: #f5f5f5
	height: 64px;
	
	.page-header-main
		margin: 0 auto
		width: 1140px
		padding: 0 19px

		.page-title
			font-weight: 300
			font-size: 1.25em
			line-height: 3.2em
			vertical-align: middle
			text-transform: uppercase
			color: #6a7a8a


.hero
	position: relative
	z-index: 100
	height: 100%

	.hero-content
		position: relative
		height: 100%

.modal-open
	overflow-y: hidden
	

.photos-modal
	display: none
	position: fixed
	top: 0
	left: 0
	bottom: 0
	right: 0
	z-index: 200
	
	.photos-content
		position: relative
		width: 100%
		height: 100%

.video-modal
	display: none
	position: fixed
	top: 0
	left: 0
	bottom: 0
	right: 0
	z-index: 200
	
	.video-content
		position: relative
		width: 100%
		height: 100%
		background: #000
		
		video
			position: absolute;
			top: 50%;
			transform: translate(0, -50%);
			min-width: 100%
			max-width: 100%

.cta
	position: relative
	padding: 30px
	margin-top: 2px
	text-align: center
	background-color: #f0f0f0
	border-bottom: 1px solid rgba(0, 0, 0, 0.07)
	border-top: 1px solid rgba(0,0,0,.1);
	z-index: 101
	
	.btn
		margin 0
		background-color: #0063CC
		color: #fff

.contact
	position: relative
	background-color: #F0F0F0
	z-index: 100
	
	.contact-header
		background-color: #fff
		color: #333
		
		.header-content
			max-width: 1140px
			margin: 0 auto
			padding: 1.25em
			lost-utility: clearfix

		.column-left
			lost-column: 2/5
			text-align: right
			
			.column-content
				padding: 2.75em 2em
		
		.column-right
			lost-column: 3/5
			border-left: 1px solid #C5CCD3
			
			.column-content
				padding: 2.75em
				padding-bottom: 5em
		
		.title
			max-width: 1140px
			margin: 0 auto
			font-weight: 300
			font-size: 1.75em
			margin-bottom: 1em
		
		.phone-label
			margin-bottom: 1em
			
			svg
				display: inline-block
				vertical-align: middle
				width: 28px
				height: 28px
				border: 2px solid #6a7a8a
				border-radius: 28px
				padding: 0px 3px 0px 5px
				fill: #6a7a8a
			
			h2
				display: inline-block
				vertical-align: middle
				margin-left: 5px
				text-transform: uppercase
				font-size: 2em
				font-weight: 400
				vertical-align: middle

		.phone
			font-size: 2em
			font-weight: 400

		p
			font-size: .9375em
			font-style: italic
	
		@media screen and (max-width: 768px)
		
			.title
				text-align: center
				margin-bottom: 0

			.column-left, .column-right
				lost-column: 1/1
			
			.column-right
				border-left: none
				border-top: 1px solid #C5CCD3
				
				.column-content
					padding-bottom: 1.75em
				
			.column-content
				text-align: center
				

	.email-label
		lost-column: 1/3
		color: #333
		
		svg
			display: inline-block
			vertical-align: middle
			width: 28px
			height: 28px
			border: 2px solid #6a7a8a
			border-radius: 28px
			padding: 0px 3px 0px 5px
			fill: #6a7a8a
		
		h2
			display: inline-block
			vertical-align: middle
			margin-left: 5px
			text-transform: uppercase
			font-size: 2em
			font-weight: 400
			vertical-align: middle

	.contact-form
		max-width: 540px
		margin: 0 auto
		padding: 3em
		
		// label
		// 	display block
		// 	margin-bottom: 1.25em
		// 	width: 100%
			
		// 	input
		// 		display: block
		// 		margin-top: .5em
		// 		width: 100%
		// 		font-weight: 400
		// 		font-size: 1em
		// 		line-height: 3em
		// 		border: 1px solid #002349
		// 		text-indent: 1em
		// 		-moz-appearance: none
		// 		-webkit-appearance: none
		// 		-moz-border-radius: 0
		// 		-webkit-border-radius: 0
		// 		border-radius: 0
			
		// 		&:focus
		// 			border-color: #0063CC
					
		// button
		// 	background-color: #0063CC
		// 	border: none
		// 	padding: .5em 3em
		// 	color: #CFD9FC
		// 	border-radius: 1em
		// 	font-weight: 300
		// 	transition: background-color .5s ease
			
		// 	&:hover
		// 		background-color: #002349
			

.thankyou
	max-width: 1140px
	margin: 0 auto
	padding: 30px
	
	h1
		margin-bottom: 15px

.menu, .menu-photos
	margin: 0 15px
	margin-top: 15px
	lost-utility: clearfix

	.item
		lost-column: 1/3
		text-align: center
		padding-bottom: 10px
		
		a
			display: inline-block
		
		img
			margin-bottom: 30px
			box-shadow: 1px 1px 2px -1px #002349

.menu-photos
	.item
		lost-column: 1/2

@media screen and (max-width: 768px)
	.menu, .menu-photos
		.item
			lost-column: 1/1

.development
	
	.section-header, .section-main-content
		max-width: 1140px
		margin: 0 auto
		padding: 15px
		
	.section-main
		background-color: #F0F0F0;

	ul
		padding: 24px
		padding: 15px 30px
		
		li
			margin-bottom: 10px
			line-height: 1.5em
			
			&:last-of-type
				margin-bottom: 0


.highlights
	padding-top: 15px
	
	.section-content
		max-width: 1140px
		margin: 0 auto
		padding: 15px
		
	.section-header
		text-align: center
		
		.title
			font-weight: 400
			margin-bottom: 10px

	ul
		padding: 24px
		padding: 15px 30px
		
		li
			margin-bottom: 10px
			line-height: 1.5em
			
			&:last-of-type
				margin-bottom: 0

.site-footer
	position: relative
	background-color: #fff
	z-index: 100
	// padding: 0 2em

	.page-links
		lost-utility: clearfix
		padding-top: 110px
		margin-bottom: 20px
			
		.column-left, .column-right
			lost-column: 1/2
			
		h2
			font-size: 1.8em
			font-weight: 400
			text-transform: uppercase
			color: #333
			font-family: 'Source Sans Pro', sans-serif
			margin-top: 20px
			margin-bottom: 10px
			
		p
			font-size: .9375em;
			line-height: 1.5
			margin: 23px 0 20px
			
		.btn
			margin-top: 30px
			
		@media screen and (max-width: 768px)
			padding-top: 10px
		
			.column-left, .column-right
				lost-column: 1/1

	.about-us
		lost-utility: clearfix
		background-color: #f5f5f5
		padding: 40px 0
			
		h2
			font-size: 1.375em
			font-weight: 500
			color: #333
			line-height: 1.1
			margin: 20px 0 10px
			
		p
			font-size: .75em
			margin: 20px 0 0
			line-height: 1.8
			

	.bottom-links
		lost-utility: clearfix
		background-color: #f5f5f5
			
		.nav
			margin: 20px auto
			
			ul
				display: inline-block
				list-style-type: none
				overflow: hidden

				li
					display: inline-block
					// height: 100%
					vertical-align: middle
					padding: 0 15px
					
					&:first-of-type
						padding-left: 0
					
					a
						color: #3c3c3c
						font-size: 1.0625em
						// line-height: 1.17647
						
			@media screen and (max-width: 768px)
				ul
					li
						display: block
						padding: 5px 0 10px 30px
						
						&:first-of-type
							padding-left: 30px
							
	.corp-links
		text-align: center
		padding: 2em 0 0
		font-size: .75em
		color: #777
		background-color: #363636
		
		a
			color: #adadad

	.company-info
		text-align: center
		// padding: 2em 0
		font-size: .9375em
		color: #777
		background-color: #363636

		.brand
			margin: 0 auto
			width: 215px
			height: 59px
			background-size: 215px 59px
			
		p
			font-size: .8em
			margin: 20px auto
			padding: 0 15px
			line-height: 1.42em;
	
	.page-links, .about-us, .bottom-links, .company-info
		padding-left: 30px
		padding-right: 30px
		@media screen and (max-width: 991px)
			padding-left: 15px
			padding-right: 15px
				
		@media screen and (max-width: 768px)
			padding-left: 10px
			padding-right: 10px

	.company-info .company-info-content,
	.bottom-links .bottom-links-content,
	.about-us .about-us-content,
	.page-links .page-links-content
		lost-center: 1170px

		@media screen and (max-width: 991px)
			lost-center: 961px
				
		@media screen and (max-width: 768px)
			lost-center: 748px

