.slider-container
    position: fixed;
    top: 0
    left: 0
    bottom: 0
    right: 0

.slider
    position: relative
    width: 100%
    height: 100%
    cursor: pointer
    transition: background .5s
    
    img
        position: absolute;
        width: auto
        height: auto
        min-width: 100%
        min-height: 100%
        max-width: none
        max-height: none
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: transparent
        
        &:before
            content: ''
            display: block
            position: absolute
            top: 0
            left: 0
            bottom: 0
            right: 0
            background: transparent
            transition: background .5s
        
        &[data-lazy]:before
            background: #000 url(../images/loading.gif) no-repeat center center
    video
        position: absolute
        width: auto
        height: auto
        min-width: 100%
        min-height: 100%
        max-width: none
        max-height: none
        top: 50%
        left: 50%
        transform: translate(-50%,-50%)
        
.slick-initialized
    background-color: #000
    
.slider-slide
    height: 100%
    width: auto
    overflow: hidden
    background-color: #000
    opacity: 0

// Slider close
.slider-closer
    position: absolute
    right: 20px
    top: 15px
    
.slider-close
    position: relative
    border: none
    outline: none
    background: transparent
    z-index: 2
    
    .close
        width: 20px
        height: 20px
        fill: #fff
        border-radius: 20px
        transition: fill .5s
        
    &:hover .close
        fill: #0063CC

.slider-play
    padding: 0px 8px 0px 16px

.slider-pause
    padding: 0px 16px 0px 16px

.slider-play,
.slider-pause
    display: none
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    z-index: 4
    width: 80px
    height: 80px
    border: 1px solid #fff
    border-color: rgba(255, 255, 255, 0.5)
    border-radius: 60px
    fill: white
    fill: rgba(255, 255, 255, 0.5)

// Slider arrows
.slider-prev,
.slider-next
    position: absolute
    top: calc((100vh - 90px)/2 + 90px)
    // width: 16%
    // height: 100%
    margin-top: -30px
    cursor: pointer
    background: transparent
    opacity: .65
    border: none
    transition: all 0.5s ease
    z-index: 2

    svg
        // position: absolute
        // top: 50%
        // margin-top: -30px
        width: 60px
        height: 60px
        fill: #fff
        transition: all 0.5s ease
        
    &:hover
        opacity: 1
        

.slider-prev
    left: 2em
    
    svg
        left: 19%
    
.slider-next
    right: 2em
    
    svg
        right: 19%
        
@media screen and (max-width: 768px)
    .slider-prev,
    .slider-next
        margin-top: -15px
        top: calc((100vh - 90px) / 3 + 90px)
        
        svg
            width: 30px
            height: 30px
            
    .slider-prev
        left: 1em

        svg
            left: 62%
        
    .slider-next
        right: 1em
        
        svg
            right: 62%

.slider-scroll
    position: absolute
    bottom: 20px
    left: 50vw
    width: 20px
    height: 15px
    cursor: pointer
    
// Slider dots
.slider-dots
    z-index: 3
    display: inline-block
    position: absolute
    right: 0
    bottom: 1.25em
    // width: 100%
    text-align: right
    font-size: 16px
    padding: 0 30px

.slider-dots-nav
    display: block
    line-height: 0
    
    li
        display: inline-block
        cursor: pointer
        margin: .5em .5em 0
        
        button
            display: block
            width: 10px
            height: 10px
            font-size: 0
            line-height: 0
            color: transparent
            border-radius: 10px
            border: 2px solid white
            outline: none
            background-color: transparent
            transition: all 0.5s
            
            &:hover,
            &:focus
                outline: none
                background-color: #0063cc
                border-color: #0063cc
                
        &.slick-active button
            background-color: #0063cc
            border-color: #0063cc
 
.slider-info
    position: absolute
    bottom: .6em
    left: .6em
    width: 394px
    border: 1px solid white
    border: 1px solid rgba(255, 255, 255, 0.8)
    color: #fff
    transition: all .7s, background-color .5s
    
    .title
        font-family: 'Open Sans', sans-serif;
        font-size: 1.125em
        font-weight: 400
        line-height: 1em
        padding: 1.0625em 2.9375em 1em 15px
        cursor: pointer
        
        .caret
            position: absolute
            right: 1em
            top: 20px
            height: 16px
            width: 16px
            fill: #fff
            transition: transform .7s
            
    .subtitle
        position: absolute
        bottom: 68px
        margin: 0
        padding: 0
        left: 0
        font-size: 1em
        font-weight: 300
        text-transform: uppercase
        opacity: 1
        transition: opacity 2s
        
        a, a:link, a:visited
            color: #fff
            color: rgba(255, 255, 255, 0.64)
            transition: color .7s
            
        a:hover
            color: #9DAFC3

    .details
        position: relative
        max-height: 0
        overflow: hidden
        transition: all .7s
        font-size: .9375em
        color: #565656
        padding: 0 15px
        opacity: 0
        
        p
            margin-bottom: 20px

        .buttons
            list-style-type: none
            
            li
                display: block
                font-size: .8em
                color: #fff
                line-height: 5em
                text-align: center 
                
                a, a:hover, a:focus, a:visited
                    color: #fff
                    
            .price
                background-color: #4C647D
                lost-column: 1/3 0 5px no-flex
                
            .more-link
                text-transform: uppercase
                background-color: #688D68
                lost-column: 2/3 0 5px no-flex
                
                a
                    display: block
    
    &.slider-info-open
        background-color: #fff
        color: #565656
        padding-bottom: 15px
        
        .title .caret
            transform: rotate3d(0,0,1, -180deg)
            fill: #565656

        .subtitle
            opacity: 0
            pointer-events: none
            transition: opacity .2s

        .details
            max-height: 300px
            opacity: 1

@media screen and (max-width: 991px)
    .slider-scroll
        left: 61vw
        
    .slider-dots
        max-width: 245px

@media screen and (max-width: 768px)
    .slider-scroll
        display: none
        
    .slider-dots
        display: none
        
    .slider-info
        width: auto
        right: .6em
