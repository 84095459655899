.container
    lost-center: 1140px no-flex

.container-flex
    lost-center: 1140px

.row
    lost-flex-container: row

.col-full
    lost-column: 1/1

.col-half
    lost-column: 1/2 flex
    
.col-quarter
    lost-column: 1/4 flex

@media screen and (max-width: 768px)
    .col-full-md
        lost-column: 1/1 flex
        
    .col-half-md
        lost-column: 1/2 flex

    .col-quarter-md
        lost-column: 1/4 flex
