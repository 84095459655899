
.btn, .btn-img .label
	display: inline-block
	position: relative
	margin: 0 auto 20px
	padding: 1em 3em
	max-width: 100%
	border: 1px solid #fff
	outline: none
	background-color: #002349
	font-size: .875em
	font-weight: 300
	color: #fff
	transition: all .5s
	
	a&:link, a&:visited
		color: #fff
	
.btn
	a:hover &, a:focus &
	&:hover, &:focus
	a&:hover, a&:focus
		border: 1px solid #002349
		background-color: #fff
		color: #002349

.btn-img
	background-color: transparent
	border: none
	outline: none
	max-width: 100%

	&:hover .label
		border: 1px solid #002349
		background-color: #fff
		color: #002349

a[href$=".pdf"] .btn:before,
a.btn[href$=".pdf"]:before
	content: ''
	display: block
	position: absolute
	opacity: 0
	top: 2px
	right: 2px
	width: 8px
	height: 8px
	background: url('../images/external-link.svg') 0/8px no-repeat
	transition: opacity .5s

a[href$=".pdf"]:hover .btn:before,
a.btn[href$=".pdf"]:hover:before
	opacity: 1
