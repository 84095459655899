
.offices
    lost-center: 1140px
    padding-top: 30px

.office
    lost-column: 1/2
    margin-bottom: 30px

.office-image
    lost-column: 1/2 2 5px

.office-title
    font-size: 18px
    margin-top: 10px

.office-info
    lost-column: 1/2 2 5px

.office-info > *
    margin-bottom: 15px
    
.office-info .svg-icon
    margin-right: 0

.office-address, .office-contact
    padding-left: 35px

.office-content
    border: 1px solid #d3d3d3

.office-map-container
    border-top: 2px solid #d0d0d0
    width: 100%

.office-map
    height: 250px

@media screen and (max-width: 991px)

    .offices
        lost-center: 961px

    .office
        lost-column: 1/1
        margin-bottom: 25px
        
    .office-image
        lost-column: 1/1

    .office-info
        lost-column: 1/1
        padding-left: 10px    

@media screen and (max-width: 768px)

    .offices
        lost-center: 748px

// @media screen and (max-width: 768px)
//     .office
//         lost-column: 1/1
//         margin-bottom: 25px
        
//     .office-image
//         lost-column: 1/1

//     .office-info
//         lost-column: 1/1
//         padding-left: 10px
