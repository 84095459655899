
/* Slider */
.slick-loading .slick-list
{
    background: #fff url('../images/ajax-loader.gif') center center no-repeat;
}

.slick-list, .slick-track {
	height: 100%
}

.slick-slide
{
	position: relative
}

.slick-track .slick-slide:after
{
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.45);
  transition: background 1s ease;
  pointer-events: none;
}

.slick-track .slick-slide.slick-current:after
{
	background: transparent
}

