.agent-login
	text-align: center
	background-color: #363636
	padding: 2em 20px 0
	
	h1
		margin-bottom: 5px
		font-size: 14px
		text-transform: uppercase
		color: #8c8c8c

	input
		display: inline-block
		width: 100px
		height: 2.5em
		font-size: 12px
	
	button
		padding: 0.322em .874em
		vertical-align: top
		margin-bottom: 0
		
